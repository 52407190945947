import { api } from "../../config/apiConfig";
import { FIND_PRODUCT_BY_CATEGORY_FAILURE, FIND_PRODUCT_BY_CATEGORY_REQUEST, FIND_PRODUCT_BY_CATEGORY_SUCCESS, FIND_PRODUCT_BY_ID_FAILURE, FIND_PRODUCT_BY_ID_REQUEST, FIND_PRODUCT_BY_ID_SUCCESS } from "./ActionType";

export const findColorSize = (reqData) => async()=>{
    try{
        const {data}=await api.get(`products/colorsize/${reqData}`)
        return data;
    }catch{  }{
        return "";
    }
};

export const postProductColorSize = (reqData) => async()=>{
    try{
        const {data}=await api.post(`InsertSanPhamMauSize/`,reqData)
        return data;
    }catch{  }{
        return "";
    }
};

export const postNewProduct = (reqData) => async()=>{
    try{
        const {data}=await api.post(`InsertProduct/`,reqData)
        return data;

    }catch{  }{
        return "";
    }
};

export const GetNewProduct = (reqData) => async()=>{
    try{
        const {data}=await api.get(`newproducts/${reqData.tuNgay}/${reqData.denNgay}`)
        return data;

    }catch{  }{
        return "";
    }
};

export const GetStore = (reqData) => async()=>{
    try{
        const {data}=await api.get(`getStore/${reqData}`)
        return data;

    }catch{  }{
        return "";
    }
};

export const GetProductById = (reqData) => async()=>{
    try{
        const {data}=await api.get(`getProductById/${reqData}`)
        return data;

    }catch{  }{
        return "";
    }
};

export const ActiveProductById = (maSanPham,code) => async()=>{
    try{
        const {data}=await api.put(`/activeproductById/${maSanPham}/${code}`)
        return data;
    }catch{  }{
        return "";
    }
};

export const GetProductColorSize = (reqData) => async()=>{
    try{
        const {data}=await api.get(`productcolorsize/getproductbycode/${reqData}`)
        return data;

    }catch{  }{
        return "";
    }
};

export const DeleteProduct = (code) => async()=>{
    try{
        const {data}=await api.delete(`product/delectproduct/${code}`)
        return data;

    }catch{  }{
        return "";
    }
};
