import React, { useState } from 'react'
import { DeleteProduct, GetProductColorSize } from '../../state/Product/Action';
import { useDispatch } from 'react-redux';
import { ActiveProductColorSize, DeleteProductColorSize, UnActiveProductColorSize } from '../../state/Color/Action';


export default function UpdateProduct() {
    const [maSanPham, setMaSanPham] = useState('')
    const [_datatable, _setDataTable] = useState([]);
    const dispatch = useDispatch();

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            dispatch(GetProductColorSize(maSanPham)).then((Result) => {
                _setDataTable(Result?.product)
            });
        }
        else
        {
            if (event.key === 'Delete') {
                dispatch(DeleteProduct(maSanPham)).then((Result) => {
                    dispatch(GetProductColorSize(maSanPham)).then((Result) => {
                        _setDataTable(Result?.product)
                    });
                });
            }
        }
    };

    const handleDeleteProductColorSize = (code) => {
        dispatch(DeleteProductColorSize(code)).then((Result) => {
            dispatch(GetProductColorSize(maSanPham)).then((Result) => {
                _setDataTable(Result?.product)
            });
        });
    }
    const handleActiveProductColorSize = (code) => {
        dispatch(ActiveProductColorSize(code)).then((Result) => {
            dispatch(GetProductColorSize(maSanPham)).then((Result) => {
                _setDataTable(Result?.product)
            });
        });
    }
    const handleRemoveActiveProductColorSize = (code) => {
        dispatch(UnActiveProductColorSize(code)).then((Result) => {
            dispatch(GetProductColorSize(maSanPham)).then((Result) => {
                _setDataTable(Result?.product)
            });
        });
    }

    return (
        <div className='p-5 grid mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>

            <div className="w-1/5 mb-3 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input
                    type="text"
                    name="maSanPham"
                    id="maSanPham"
                    autoComplete="maSanPham"
                    onChange={(event) => setMaSanPham(event.target.value)}
                    onKeyDown={handleKeyDown}
                    onInput={toInputUppercase}
                    className="border-1 border-gray-500 p-2 rounded-md focus:outline-teal-500 w-full h-full"
                />
            </div>

            <div className=' grid grid-cols-1 pt-1 '>
                <table class="min-w-full border-collapse block md:table">
                    <thead class="block md:table-header-group">
                        <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">STT</th>
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã sản phẩm</th>
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã màu</th>
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã hex</th>
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tên màu</th>
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã size</th>
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Quản lý</th>
                            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell"></th>
                        </tr>
                    </thead>
                    <tbody class="block md:table-row-group">
                        {_datatable?.map((row, index) => (
                            <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{index + 1}</td>
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.MaSanPham}</td>
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.MaMau}</td>
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                                    <div className='flex space-x-3'>
                                        <span className='w-7 h-7 ' style={{ backgroundColor: `#${row.MaHex}` }} ></span>
                                        <span className='pl-0.3 text-lg'>{row.MaHex} </span>
                                    </div>
                                </td>
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.TenMau}</td>
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.MaSize}</td>
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.QuanLy}</td>
                                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                                    <button onClick={() => handleActiveProductColorSize(row.MaSanPham.trim() + row.MaMau.trim() + row.MaHex.trim() + row.MaSize.trim())} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded ml-1">quản lý</button>
                                    <button onClick={() => handleRemoveActiveProductColorSize(row.MaSanPham.trim() + row.MaMau.trim() + row.MaHex.trim() + row.MaSize.trim())} class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 border border-yellow-500 rounded ml-1">Bỏ quản lý</button>
                                    <button onClick={() => handleDeleteProductColorSize(row.MaSanPham.trim() + row.MaMau.trim() + row.MaHex.trim() + row.MaSize.trim())} class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded ml-1">Xóa</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
