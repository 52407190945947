import { Button, Progress, Space, Spin, Typography, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from "../../config/apiConfig"
import axios from "axios"
import { useDispatch } from 'react-redux';
import { deletePictureNB, getPictureNB } from '../../state/Picture/Action';

export default function UploadImageNB() {
  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();
  const [_danhSachSanPham, _setDanhSachSanPham] = useState([]);
  const [_maSanPham, _setMaSanPham] = useState([]);
  const navigate = useNavigate();
  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
       dispatch(getPictureNB(_maSanPham)).then((Result) => {
        _setDanhSachSanPham(Result?.hinhAnh)
        
      });

    }
  };
  const handleRemoveHinhAnh = (maSanPham) => {
    dispatch( deletePictureNB(maSanPham)).then(() => {
      

      dispatch(getPictureNB(_maSanPham)).then((Result) => {
        _setDanhSachSanPham(Result?.hinhAnh)
      });


    });

  }
  useEffect(() => {
    if (!jwt) {

      navigate("/");
    }

  }, [jwt])
  // const [files,setFiles] = useState({})
  const handleFileUpload = ({ file, onSuccess, onError, onProgress }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = axios.post(`${API_BASE_URL}HinhAnhNB/UploadImages`, formData, {
        headers: {

          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${jwt}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress({ percent: percentCompleted }, file);
        },
      });
      onSuccess(response.data, file);
    } catch (error) {
      // Handle upload error
      onError(error, file);
    }



  };
  return (
    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-4">
      <h1 className=' w-full bg-yellow-200 text-red-800 align-middle text-center text-xl mb-1'> TRANG UPLOAD ẢNH NỘI BỘ </h1>
      <div className="border rounded-md w-1/5 ">
        <input
          type="text"
          name="maSanPham"
          id="maSanPham"
          autoComplete="maSanPham"
          onChange={(event) => _setMaSanPham(event.target.value)}
          onKeyDown={handleKeyDown}
          onInput={toInputUppercase}
          className="border-1 border-gray-500 p-2  rounded-md focus:outline-teal-500 w-full h-full "
        />
      </div>
      <div className=' grid grid-cols-1 pt-1 pb-4 '>
        <table class="min-w-full border-collapse block md:table">
          <thead class="block md:table-header-group">
            <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">STT</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã Sản Phẩm</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Quản Lý</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell"></th>
            </tr>
          </thead>
          <tbody class="block md:table-row-group">
            {_danhSachSanPham?.map((ds, index) => (
              <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{index + 1}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <div className='flex space-x-3'>
                    <span className='pl-0.3 text-lg'>{ds.MaSanPham} </span>
                  </div>
                </td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell"> <div className='flex space-x-3'>
                    <span className='pl-0.3 text-lg'>{ds.QuanLy} </span>
                  </div></td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <button onClick={() => handleRemoveHinhAnh(ds.MaSanPham)} class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded ml-1">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className=' flex justify-center items-center mx-auto max-w-7xl h-[600px]'>
        {/* action={`${API_BASE_URL}UploadImages`}  */}
        <Upload.Dragger className='w-full h-full' multiple listType='picture' customRequest={handleFileUpload}
          showUploadList={{ showPreviewIcon: true }}
          accept='jpg'
          iconRender={() => {
            return <Spin></Spin>
          }}

          progress={{
            strokeWidth: 3,
            strokeColor: {
              "0%": "#f0f",
              "100%": "#ff0",
            },
          }}
        >
          Drag files here Or <br />
          <Button>Click Upload</Button>
        </Upload.Dragger>
      </div>
    </div>

  )
}
