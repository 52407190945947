import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import { getUser } from '../../state/Auth/Action'
import { deleteColor, getAllColor, postColor } from '../../state/Color/Action';
import { Button, Grid, TextField } from '@mui/material'

export default function Color() {
  const [_color, _setColor] = useState([]);
  const dispatch = useDispatch();
  // const jwt = localStorage.getItem("jwt");
  // const { auth } = useSelector(store => store)

  const handleAddNewColor = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget);
    const colorData = {
      MaMau: data.get("maMau"),
      TenMau: data.get("tenMau"),
    }
    dispatch(postColor(colorData)).then((Result) => {
      // _setColor(Result?.color)
      dispatch(getAllColor()).then((Result) => {
        _setColor(Result?.color)
      });

    });

  }

  useEffect(() => {
    // if (jwt) {
    //   dispatch(getUser(jwt));
    // }
    dispatch(getAllColor()).then((Result) => {
      _setColor(Result?.color)
    });
  }, [])

  const handleRemoveColor = (colorData) => {
    dispatch(deleteColor(colorData)).then((Result) => {
      // _setColor(Result?.color)

      dispatch(getAllColor()).then((Result) => {
        _setColor(Result?.color)
      });


    });

  }
  return (
    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pt-5">
      <div  >
        <form onSubmit={handleAddNewColor} className='flex space-x-3'>

          <TextField id='maMau' inputProps={{ maxLength: 6 }} name='maMau' label='Mã màu' />
          <TextField id='tenMau' name='tenMau' label='Tên màu' />
          <Button className=' w-[8rem]' type='submit' variant='contained' size='large' sx={{ padding: ".8rem 0", bgcolor: "#9155FD" }}>Thêm màu</Button>

        </form>
      </div>

      <label className="block text-sm font-medium leading-6 text-gray-900">
        Danh sách sản phẩm màu size:
      </label>
      <div className=' grid grid-cols-1 pt-1 pb-4 '>
        <table class="min-w-full border-collapse block md:table">
          <thead class="block md:table-header-group">
            <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">STT</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã Màu</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tên Màu</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell"></th>
            </tr>
          </thead>
          <tbody class="block md:table-row-group">
            {_color?.map((color, index) => (
              <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{index + 1}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <div className='flex space-x-3'>
                    <span className='w-7 h-7 ' style={{ backgroundColor: `#${color.MaMau}` }} ></span>
                    <span className='pl-0.3 text-lg'>{color.MaMau} </span>

                  </div>


                </td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{color.TenMau}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <button onClick={() => handleRemoveColor(color.MaMau)} class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded ml-1">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  )
}
