import { api } from "../../config/apiConfig";
export const getPicture = (maSanPham) => async()=>{
    try{
        const {data}=await api.get(`/HinhAnh/getHinhAnh/${maSanPham}`)
        return data;
    }catch{  }{
        return "";
    }
};

export const deletePicture = (maSanPham) => async()=>{
    try{
        const {data}=await api.delete(`/HinhAnh/Delete/${maSanPham}`)
        return data;
    }catch{  }{
        return "";
    }
};

export const getPictureNB = (maSanPham) => async()=>{
    try{
        const {data}=await api.get(`/HinhAnhNB/getHinhAnh/${maSanPham}`)
        return data;
    }catch{  }{
        return "";
    }
};

export const deletePictureNB = (maSanPham) => async()=>{
    try{
        const {data}=await api.delete(`/HinhAnhNB/Delete/${maSanPham}`)
        return data;
    }catch{  }{
        return "";
    }
};