import React from 'react'
import {  Navigate, Route, Routes } from 'react-router-dom'
import Home from '../components/Home/Home'
import UploadImage from '../components/UploadImage/UploadImage'
import Product from '../components/Product/Product'
import ProductColorSize from '../components/Product/ProductColorSize'
import Color from '../components/Color/Color'
import UpdateProduct from '../components/Product/UpdateProduct'
import Order from '../components/Order/Order'
import ListProducts from '../components/Product/ListProducts'
import UploadImageNB from '../components/UploadImage/UploadImageNB'
import LoginForm from '../auth/LoginForm'

export default function () {
  return (
    <Routes>
        <Route path='/' element={<Navigate to='/home'/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/login' element={<LoginForm/>} />
        <Route path='/register' element={<Home/>} />
        <Route path='/uploadimage' element={< UploadImage />} />
        <Route path='/uploadanhnoibo' element={< UploadImageNB />} />
        <Route path='/product' element={< Product />} />
        <Route path='/productcolorsize' element={< ProductColorSize />} />
        <Route path='/color' element={< Color />} />
        <Route path='/updateproduct' element={< UpdateProduct />} />
        <Route path='/order' element={< Order />} />
        <Route path='/listproducts' element={< ListProducts />} />
       
    </Routes>
  )
}
