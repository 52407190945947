import React, {  Fragment,useState } from 'react'
import MsBoxModal from '../Modal/MsBoxModal'
import { findColorSize, postNewProduct, postProductColorSize } from '../../state/Product/Action';
import { useDispatch } from 'react-redux';
import { IMAGES_URL_NB } from '../../config/apiConfig';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const sizesAll = [
  { MaSize: 'FS' },
  { MaSize: 'S' },
  { MaSize: 'M' },
  { MaSize: 'L' },
  { MaSize: 'XL' },
  { MaSize: '2XL' },
  { MaSize: '3XL' },
  { MaSize: '20' },
  { MaSize: '22' },
  { MaSize: '25' },
  { MaSize: '26' },
  { MaSize: '27' },
  { MaSize: '28' },
  { MaSize: '29' },
  { MaSize: '30' },
  { MaSize: '31' },
  { MaSize: '32' },
  { MaSize: '33' },
  { MaSize: '34' },
  { MaSize: '35' },
  { MaSize: '36' },
]

export default function ProductColorSize() {
  const [selectedHex, setSelectedHex] = useState([])
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [maSanPham, setMaSanPham] = useState('')
  const [_sanPham, _setSanPham] = useState('')
  const [_colors, _setColors] = useState([])
  const [_hexAll, _sethexAll] = useState([])
  const [selectedColors, setSelectedColors] = useState('')
  const [message, setMessage] = useState('')


  const [selectedSizes, setSelectedSizes] = useState([])

  const [_sizes, _setSizes] = useState([])
  const dispatch = useDispatch();
  const [datatable, setDataTable] = useState([]);
  

  const handlePushDataTable = () => {
    const newItems = [];
    if(selectedSizes?.length>0)
      for (let i in selectedSizes) {
        if (!datatable.find(item => (item.MaSanPham === maSanPham && item.MaMau === selectedColors && item.MaHex === selectedHex.MaMau && item.MaSize === selectedSizes[i]))) {
          newItems.push({Id: maSanPham + selectedColors + selectedHex.MaMau + selectedSizes[i], MaSanPham: maSanPham, MaMau: selectedColors, MaHex: selectedHex.MaMau, TenMau: selectedHex.TenMau, MaSize: selectedSizes[i] });
        }
      }
    else
    for (let i in _sizes) {
      if (!datatable.find(item => (item.MaSanPham === maSanPham && item.MaMau === selectedColors && item.MaHex === selectedHex.MaMau && item.MaSize === _sizes[i].MaSize))) {
        newItems.push({Id: maSanPham + selectedColors + selectedHex.MaMau + _sizes[i].MaSize, MaSanPham: maSanPham, MaMau: selectedColors, MaHex: selectedHex.MaMau, TenMau: selectedHex.TenMau, MaSize: _sizes[i].MaSize });
      }
    }
    setDataTable(prevList => [...prevList, ...newItems]);
  }
  const handleRemoveDataTable = (id) => {
    const newItems = datatable.filter(item => item.Id !== id);
    
    setDataTable(newItems);
    
  }
  const handleSave = () => {
    dispatch( postProductColorSize(datatable));
    setDataTable([]);
    setMessage("Thêm sản phẩm mới màu size thành công.");
    handleMsBox();
  }
  const handleAddNewProduct = () => {
    
    dispatch( postNewProduct(maSanPham));
setMessage("Thêm sản phẩm mới thành công.");
    handleMsBox();
  }
  // function for messageBox
  const handleClose = () => {
    setOpenAuthModal(false);

  };

  const toggleColor = (color) => {
    setSelectedColors(color)
  }
  const toggleHex = (MaMau,TenMau) => {
    
    
    setSelectedHex({ MaMau: MaMau, TenMau: TenMau })
  }

  const toggleSize = (size) => {
    setSelectedSizes((prevSize) => prevSize.includes(size) ? prevSize.filter((c) => c !== size) : [...prevSize, size])
  }
  // function for input maSanPham
  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const kq = dispatch(findColorSize(maSanPham)).then((Result) => {
        _setColors(Result?.colors)
        _setSizes(Result?.sizes)
        _setSanPham(Result?.product)
        _sethexAll(Result?.mau)
      });
      
    }
  };
  const handleMsBox = () => {

    setOpenAuthModal(true);
  }
  return (
    <div className='p-5'>
      <div className="mt-2">
        <div className="w-1/5 mb-3 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
          <input
            type="text"
            name="maSanPham"
            id="maSanPham"
            autoComplete="maSanPham"
            onChange={(event) => setMaSanPham(event.target.value)}
            onKeyDown={handleKeyDown}
            onInput={toInputUppercase}
            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          />
        </div>
        <div>
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded" onClick={handleAddNewProduct} >Thêm sản phẩm mới</button>
        </div>
      </div>
      <hr />
      <span> {_sanPham[0]?.maSanPham}  -  {_sanPham[0]?.tenSanPham} - {_sanPham[0]?.donGia} </span>
      <label className="mt-3 block text-sm font-medium leading-6 text-gray-900">
        Tất cả các màu của mã: {maSanPham}
      </label>
      <div className="space-y-4">
        <ul className=' grid grid-cols-12 px-5 gap-5'>
          {_colors.map((color, index) => (
            <li key={index} className={` border-[0.5px] rounded-lg text-center text-[14px] py-[2px] cursor-pointer ${selectedColors.includes(`${color.MaMau}`) ? ' bg-gray-200 text-red-800' : ''}`}
              onClick={() => toggleColor(`${color.MaMau}`)}
            >
              <div className='flex'>
               <img src={IMAGES_URL_NB + color.HinhAnh.trim() + ".jpg"} onClick={() => (setMessage(<img src={IMAGES_URL_NB + color.HinhAnh.trim() + ".jpg"} />), handleMsBox())} className=' h-14 w-10 cursor-pointer ' />
              {color.MaMau}</div>
            </li>
          ))}
        </ul>
      </div>


<label className="block text-sm font-medium leading-6 text-gray-900">
        Tất cả các Màu:
      </label>
      <div className="space-y-4">
        <ul className=' grid grid-cols-10 px-2 gap-2'>
          {_hexAll?.map((hex, index) => (
            <li key={index} style={{ backgroundColor: `#${hex.MaMau}` }} className={` h-14 pt-4 border-[0.5px] rounded-lg text-center text-[14px] py-[2px] cursor-pointer ${selectedHex.MaMau === (`${hex.MaMau}`) ? ' border-2 border-red-600 bg-neutral-900 text-white' : ''}`}
              onClick={() => toggleHex(`${hex.MaMau}`,`${hex.TenMau}`)}
            >
              {hex.TenMau}
            </li>
          ))}
        </ul>
      </div>





     
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Tất cả các size của mã: {maSanPham}
      </label>
      <div className="space-y-4">
        <ul className=' grid grid-cols-12 px-5 gap-5'>
          {_sizes.map((size, index) => (
            <li key={index} className={` border-[0.5px] rounded-lg text-center text-[14px] py-[2px] cursor-pointer ${selectedColors.includes(`${size.MaSize}`) ? 'bg-neutral-900 text-white' : ''}`}
            //onClick={() => toggleColor(`${size.MaSize}`)}
            >
              {size.MaSize}
            </li>
          ))}
        </ul>
      </div>

      

      <label className="block text-sm font-medium leading-6 text-gray-900">
        Tất cả các size:
      </label>
      <div className="space-y-4">
        <ul className=' grid grid-cols-12 px-5 gap-5'>
          {sizesAll.map((size, index) => (
            <li key={index} className={` border-[0.5px] rounded-lg text-center text-[14px] py-[2px] cursor-pointer ${selectedSizes.includes(`${size.MaSize}`) ? 'bg-neutral-900 text-white' : ''}`}
              onClick={() => toggleSize(`${size.MaSize}`)}
            >
              {size.MaSize}
            </li>
          ))}
        </ul>
      </div>
      <div>Mã Sản Phẩm: {maSanPham} - {selectedColors} - {selectedHex.MaMau} - {selectedSizes}</div>
      <div className='w-full flex justify-end'>
      <button  className =" bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded" onClick={handlePushDataTable} >Thêm vào danh sách</button>
      </div>
      
      <label className="block text-sm font-medium leading-6 text-gray-900">
        Danh sách sản phẩm màu size:
      </label>
      <div className=' grid grid-cols-1 pt-1 '>
        <table class="min-w-full border-collapse block md:table">
          <thead class="block md:table-header-group">
            <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">STT</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã sản phẩm</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã Màu</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã Hex</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tên Màu</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã Size</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell"></th>
            </tr>
          </thead>
          <tbody class="block md:table-row-group">
            {datatable?.map((row, index) => (
              <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{index + 1}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.MaSanPham}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.MaMau}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.MaHex}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.TenMau}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.MaSize}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <button onClick={()=> handleRemoveDataTable(row.MaSanPham + row.MaMau + row.MaHex + row.MaSize )} class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 border border-red-500 rounded ml-1">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='pt-3 w-full flex justify-end'>
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded" onClick={handleSave} >Thêm danhs sách sản phẩm</button>
        </div>
      <MsBoxModal handleClose={handleClose} open={openAuthModal} Message={message} />
    </div>

  )
}
