import React, { Fragment, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../state/Auth/Action'
import { deleteColor, getAllColor, postColor } from '../../state/Color/Action';
import { Button, Grid, TextField } from '@mui/material'
import { Dialog, Transition } from '@headlessui/react'
import QrScanner from 'react-qr-scanner';
import { ActiveProductById, GetProductById } from '../../state/Product/Action';

export default function ListProducts() {
  const dispatch = useDispatch();
  const [_danhSachSanPham, _setDanhSachSanPham] = useState([]);
  const [_maSanPham, _setMaSanPham] = useState('');

  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      dispatch(GetProductById(_maSanPham)).then((Result) => {
        _setDanhSachSanPham(Result?.listProducts)
        console.log(Result?.listProducts)
      });

    }
  };
  const handleUpdate = (Id, Status) => {
    dispatch(ActiveProductById(Id, Status)).then((Result) => {
      dispatch(GetProductById(_maSanPham)).then((Result) => {
        _setDanhSachSanPham(Result?.listProducts)
        console.log(Result?.listProducts)
      });
    });
  }
  return (
    <div className="mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8 pt-5">
      <div>
      </div>
      <div >
        <div className='flex border-b border-blue-300 pb-2'>
          <div className="border rounded-md w-1/5  ">
            <input
              type="text"
              name="maSanPham"
              id="maSanPham"
              autoComplete="maSanPham"
              onChange={(event) => _setMaSanPham(event.target.value)}
              onKeyDown={handleKeyDown}
              onInput={toInputUppercase}
              className="border-1 border-gray-500 p-2 rounded-md focus:outline-teal-500 w-full h-full "
            />
          </div>
        </div>
      </div>

      <label className="block text-sm font-medium leading-6 text-gray-900">
        Danh sách sản phẩm:
      </label>
      <div className=' grid grid-cols-1 pt-1 pb-4 ' >
        <table class="min-w-full border-collapse block md:table">
          <thead class="block md:table-header-group">
            <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">STT</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã sản phẩm</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tên sản phẩm</th>
              {/* <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mô tả</th> */}
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Đơn giá</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã nhóm</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tên nhóm</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã loại</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tên loại</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Ngày sản xuất</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Quản lý</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell"></th>
            </tr>
          </thead>
          <tbody class="block md:table-row-group">
            {_danhSachSanPham?.map((sanpham, index) => (
              <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{index + 1}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.MaSanPham}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.TenSanPham}</td>
                {/* <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.MoTa}</td> */}
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.DonGia}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.MaNhomSanPham}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.TenNhomSanPham}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.LoaiSanPham}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.TenLoaiSanPham}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.NgaySanXuat}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{sanpham.QuanLy}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <div className=' fex m-1 '>
                    <button onClick={() => handleUpdate(sanpham.MaSanPham, "1")} class="bg-lime-500 hover:bg-lime-700 text-white font-bold py-1 px-2 border border-lime-500 rounded m-0.5 ">Quản lý</button>
                    <button onClick={() => handleUpdate(sanpham.MaSanPham, "0")} class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 border border-yellow-500 rounded m-0.5 ">Bỏ quản lý</button>

                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  )
}
