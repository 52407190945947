import React, { Fragment, useEffect, useState } from 'react'
import MsBoxModal from '../Modal/MsBoxModal'
import { GetNewProduct, GetStore, findColorSize, postProductColorSize } from '../../state/Product/Action';
import { useDispatch } from 'react-redux';
import { Row } from 'antd';
import dayjs from 'dayjs';
import { IMAGES_URL_NBNS } from '../../config/apiConfig';


export default function Product() {
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [message, setMessage] = useState('')
  const [maSanPham, setMaSanPham] = useState('')
  const [_product, _setProduct] = useState([])
  const [_store, _setStore] = useState([])
  const dispatch = useDispatch();
  // function for messageBox
  const handleCloseModal = () => {
    setOpenAuthModal(false);
  };
  let currentDate = new Date();
  const [tuNgay, setTuNgay] = React.useState(new Date().toISOString().substring(0, 10));
  const [denNgay, setDenNgay] = React.useState(new Date().toISOString().substring(0, 10));

  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      dispatch(GetStore(maSanPham)).then((Result) => {
        _setStore(Result?.store)
      });

    }
  };
  const handledoubleclick = (_maSP) => {
    setMaSanPham(_maSP)
    dispatch(GetStore(maSanPham)).then((Result) => {
      _setStore(Result?.store)
    });
  };
  const haldleKienTra = () => {
    const soNgay = (dayjs(denNgay) - dayjs(tuNgay)) / (1000 * 3600 * 24)
    if (soNgay < 32 & soNgay > -1) {
      dispatch(GetNewProduct(data)).then((Result) => {
        _setProduct(Result?.newProduct)
        if (!Result?.newProduct) {
          setMessage("Không tìm thấy sản phẩm.");
          handleMsBox();
        }

      });
    }
    else
    {
      setMessage("Vui lòng kiểm tra ngày tháng.");
          handleMsBox();
    }
  }
  const handleMsBox = () => {
    setOpenAuthModal(true);
  }
  const handleDatetimeChange = (e) => {
    setTuNgay(e.target.value);
  };
  const data = {
    tuNgay: tuNgay,
    denNgay: denNgay
  }
  return (
    <div>
      <div className='p-5 grid mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
        <div className='flex border-b border-blue-300 pb-2'>
          <span className='pt-1 pr-2'>Từ ngày:</span>
          <input
            type="date" // Use the appropriate input type for datetime
            value={tuNgay}
            onChange={handleDatetimeChange}
            className='pr-4'
          />
          <span className='pt-1 pr-2'>Đến ngày:</span>
          <input
            type="date" // Use the appropriate input type for datetime
            value={denNgay}
            onChange={(e) => (setDenNgay(e.target.value))}
            className='pr-4'
          />
          <button class=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded " onClick={haldleKienTra} >Kiểm tra</button>
          <span className='w-2'> </span>
          <div className="border rounded-md w-1/5  ">
            <input
              type="text"
              name="maSanPham"
              id="maSanPham"
              autoComplete="maSanPham"
              onChange={(event) => setMaSanPham(event.target.value)}
              onKeyDown={handleKeyDown}
              onInput={toInputUppercase}
              className="border-1 border-gray-500 p-2 rounded-md focus:outline-teal-500 w-full h-full "
            />
          </div>
        </div>
      </div>
      <div className='p-5 grid mx-auto max-w-9xl px-2 sm:px-6 lg:px-8'>
        {_store?.length>0 ? (
          <div className=' grid grid-cols-1  border-b border-blue-300 pb-2 '>
            <table class="min-w-full border-collapse block md:table">
              <thead class="block md:table-header-group">
                <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">MaSP</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">TONG</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">HBT</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">NT</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">CMT8</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">NKKN</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">LVS</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">LDH</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">HG</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">QT</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">TN</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">PVC</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">TD</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">BD</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">BH</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">DN</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">NTR</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">BMT</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">PT</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">HUE</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">V</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">BT</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">CB</th>
                  <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">CG</th>
                </tr>
              </thead>
              <tbody class="block md:table-row-group">
                {_store?.map((row, index) => (
                  <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell"><div className='flex'>
                    <img src={IMAGES_URL_NBNS + row.maSanPham.trim().substring(0,row.maSanPham.trim().length-3) +'.jpg'} className=' h-6 w-4 cursor-pointer ' onClick={() => (setMessage(<img src={ IMAGES_URL_NBNS + row.maSanPham.trim().substring(0,row.maSanPham.trim().length-3) + '.jpg'} />), handleMsBox())} />
                    <span className='pl-1'>  {row.maSanPham} </span> </div></td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.tong}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.hbt}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.nt}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.cmT8}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.nkkn}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.lvs}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.ldh}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.hg}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.qt}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.tn}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.pvc}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.td}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.bd}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.bh}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.dn}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.ntr}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.bmt}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.pt}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.hue}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.v}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.bt}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.cb}</td>
                    <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{row.cg}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : ("")}
        <div className='p-1 grid grid-cols-4'>
          {_product?.map((item) => (
            <div className=' grid grid-cols-2'>
              <div className='p-1' >  <img src={ IMAGES_URL_NBNS + item.maSanPham + '.jpg'} className=' h-60 w-40 cursor-pointer' onClick={() => (setMessage(<img src={ IMAGES_URL_NBNS + item.maSanPham + '.jpg'} />), handleMsBox())} />  </div>
              <div>
                <span onClick={() => handledoubleclick(item.maSanPham)} className=' cursor-pointer'>{item.maSanPham} </span>
                <br />
                {item.tenSanPham}<br />
                {item.donGia}<br />
                {item.ngaySanXuat}<br />
              </div>
            </div>
          ))}
        </div>
      </div>
      <MsBoxModal handleClose={handleCloseModal} open={openAuthModal} Message={message} />
    </div>
  )
}
