import { Button, Grid, TextField } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { login } from '../state/Auth/Action';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget);
    const userData = {
      username: data.get("username"),
      password: data.get("password"),
    }
    dispatch(login(userData));
    navigate("/home")
    


  }
  return (
    <div className=' flex items-center justify-center h-screen bg-gray-300'>
      <div className=' bg-white h-[350px] w-[350px] rounded-lg border-solid border-2 border-gray-600 p-4'>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>

            <Grid item xs={12} >
              <TextField required id='username' name='username' label='username' fullWidth autoComplete='username' />
            </Grid>
            <Grid item xs={12} >
              <TextField required type='password' id='password' name='password' label='Password' fullWidth autoComplete='password' />
            </Grid>
            <Grid item xs={12} >
              <Button className=' bg-[#9155FD] w-full' type='submit' variant='contained' size='large' sx={{ padding: ".8rem 0", bgcolor: "#9155FD" }}>Login</Button>
            </Grid>
          </Grid>
        </form>
        <div className='flex justify-center flex-col items-center'>
          <div className='py-3 flex items-center'>
            <p>if you don't have account ?</p>
            <Button onClick={() => navigate("/register")} className=' ml-5 ' size='small'>Register</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
