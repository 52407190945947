import React, { useState, useEffect, useRef, Component } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../state/Auth/Action'
import { deleteColor, getAllColor, postColor } from '../../state/Color/Action';
import { Button, Grid, TextField } from '@mui/material'
import dayjs from 'dayjs';
import { getOrderByCustomer, getOrderDetail, updateOrder } from '../../state/Order/Action';
import MsBoxModal from '../Modal/MsBoxModal'
import ReactToPrint from 'react-to-print';
import './CustomScrollbar.css';
export const IMAGES_URL = "http://mapi.pt2000.vn/uploads/"



export default function Order() {
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const [_color, _setColor] = useState([]);
  const [_order, _setOrder] = useState([]);
  const [_orderDetail, _setOrderDetail] = useState([]);
  const dispatch = useDispatch();
  // const jwt = localStorage.getItem("jwt");
  // const { auth } = useSelector(store => store)
  let currentDate = new Date();
  const [tuNgay, setTuNgay] = React.useState(new Date().toISOString().substring(0, 10));
  const [denNgay, setDenNgay] = React.useState(new Date().toISOString().substring(0, 10));
  const [message, setMessage] = useState('')
  const [customerId, setCustomerId] = useState('null')

  // useEffect(() => {
  //   if (jwt) {
  //     dispatch(getUser(jwt));
  //   }

  // }, [jwt, auth.jwt])
  const handleCloseModal = () => {
    setOpenAuthModal(false);
  };
  const contentRef = useRef(null);
  const handlePrint = () => {
    //window.print();
    const contentToPrint = contentRef.current;
    
    if (contentToPrint) {

      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>






          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };



  const handleShowDetail = (Id, TotalCount, TotalAmount,Name,Phone,Address) => {

    dispatch(getOrderDetail(Id)).then((res) => {
      //ref={contentRef} 

      setMessage(
      <div className=' h-screen p-1 '>
      <div className='w-[600px] h-full overflow-auto custom-scrollbar'> 
      <div>
        <p className=' text-lg'>Họ tên: <span className=' font-bold text-blue-950'>{Name}</span> </p>
        <p className=' text-lg'>Điện thoại: <span className=' font-bold text-blue-950'> {Phone}</span></p>
        <p className=' text-lg'>Địa chỉ: <span className=' font-bold text-blue-950'> {Address}</span></p>

      </div>
      <table class="min-w-full border-collapse block md:table" >
        <thead class="block md:table-header-group">
          <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">STT</th>
            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã sản phẩm</th>
            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Số lượng</th>
            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Đơn giá</th>
            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Thành tiền</th>
          </tr>
        </thead>
        <tbody class="block md:table-row-group">
          {res.orderdetail?.map((order, index) => (
            <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
              <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{index + 1}</td>
              <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{
              <div className='flex'>

                 <img src={(IMAGES_URL + order.ProductID.substring(0, order.ProductID.lastIndexOf('-')) + "-00.jpg")} className=' w-[4rem] h-[6rem]' />
              {order.ProductID}
              
              </div>
              }</td>
              <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.Quantity}</td>
              <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{Intl.NumberFormat('en-US').format(order.UnitPrice)}</td>
              <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{Intl.NumberFormat('en-US').format(order.Total)}</td>
            </tr>
          ))}
        </tbody>
        <thead class="block md:table-header-group">
          <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
            <th colspan="2" class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tổng</th>

            <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-center block md:table-cell">{Intl.NumberFormat('en-US').format(TotalCount)}</th>
            <th colspan="2" class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-center block md:table-cell">{Intl.NumberFormat('en-US').format(TotalAmount)}</th>

          </tr>
        </thead>
      </table>
        {/* <div>
          <button onClick={handlePrint} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded m-0.5 "> In </button>
        </div> */}
      </div>
      </div>
      
      );

      setOpenAuthModal(true)
    });


  }

  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      haldleKienTra();

    }
  };
  const handleDatetimeChange = (e) => {
    setTuNgay(e.target.value);
  };
  const haldleKienTra = () => {
    const soNgay = (dayjs(denNgay) - dayjs(tuNgay)) / (1000 * 3600 * 24)
    const orderData = {
      TuNgay: tuNgay,
      DenNgay: denNgay,
      Id: customerId,
    }
    if (soNgay < 32 & soNgay > -1) {
      dispatch(getOrderByCustomer(orderData)).then((Result) => {
        _setOrder(Result?.order)
        if (!Result?.order) {
          setMessage("Không tìm thấy đơn hàng.");
          handleMsBox();
        }

      });
    }
    else {
      setMessage("Vui lòng kiểm tra ngày tháng.");
      handleMsBox();
    }
  }
  const handleMsBox = () => {
    setOpenAuthModal(true);
  }
  const handleUpdate =(Id,Status)=>{
    
    const orderData = {
      TuNgay: tuNgay,
      DenNgay: denNgay,
      Id: customerId,
    }
    dispatch(updateOrder(Id,Status)).then((Result) => {

      dispatch(getOrderByCustomer(orderData)).then((Result) => {
        _setOrder(Result?.order)
        if (!Result?.order) {
          setMessage("Không tìm thấy đơn hàng.");
          handleMsBox();
        }

      });
      // _setOrder(Result?.order)
      // if (!Result?.order) {
      //   setMessage("Không tìm thấy đơn hàng.");
      //   handleMsBox();
      // }

    });
  }
  return (
    <div className="mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8 pt-5">
      <div>
      </div>
      <div >
        <div className='flex border-b border-blue-300 pb-2'>
          <span className='pt-1 pr-2'>Từ ngày:</span>
          <input
            type="date" // Use the appropriate input type for datetime
            value={tuNgay}
            onChange={handleDatetimeChange}
            className='pr-4'
          />
          <span className='pt-1 pr-2'>Đến ngày:</span>
          <input
            type="date" // Use the appropriate input type for datetime
            value={denNgay}
            onChange={(e) => (setDenNgay(e.target.value))}
            className='pr-4'
          />
          <button class=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded " onClick={haldleKienTra} >Kiểm tra</button>
          <span className='w-2'> </span>
          <div className="border rounded-md w-1/5  ">
            <input
              type="text"
              name="dienThoai"
              id="dienThoai"
              autoComplete="dienThoai"
              onChange={(event) => setCustomerId(event.target.value)}
              onKeyDown={handleKeyDown}
              onInput={toInputUppercase}
              className="border-1 border-gray-500 p-2 rounded-md focus:outline-teal-500 w-full h-full "
            />
          </div>
        </div>
      </div>

      <label className="block text-sm font-medium leading-6 text-gray-900">
        Danh sách đơn hàng:
      </label>
      <div className=' grid grid-cols-1 pt-1 pb-4 ' >
        <table class="min-w-full border-collapse block md:table">
          <thead class="block md:table-header-group">
            <tr class="border border-grey-500 md:border-none block md:table-row absolute -top-full md:top-auto -left-full md:left-auto  md:relative ">
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">STT</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Mã ĐH</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Điện thoại</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Tên KH</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Địa chỉ</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Ngày</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Số lượng</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Số tiền</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell">Trạng thái</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left block md:table-cell"></th>
            </tr>
          </thead>
          <tbody class="block md:table-row-group">
            {_order?.map((order, index) => (
              <tr key={index} class="bg-gray-300 border border-grey-500 md:border-none block md:table-row">
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{index + 1}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.OrderID}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.CustomerID}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.CustomerName}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.CustomerAddress}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.OrderDate}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.TotalCount}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.TotalAmount}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">{order.OrderStatus}</td>
                <td class="p-2 md:border md:border-grey-500 text-left block md:table-cell">
                  <div className=' fex m-1 '>
                    <button onClick={() => handleShowDetail(order.OrderID, order.TotalCount, order.TotalAmount,order.CustomerName,order.CustomerID,order.CustomerAddress)} class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 border border-green-500 rounded m-0.5 ">CT</button>
                    <button onClick={() => handleUpdate(order.OrderID, "Xác nhận")} class="bg-lime-500 hover:bg-lime-700 text-white font-bold py-1 px-2 border border-lime-500 rounded m-0.5 ">XN</button>
                    <button onClick={() => handleUpdate(order.OrderID, "Vận chuyển")} class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 border border-yellow-500 rounded m-0.5 ">VC</button>
                    <button onClick={() => handleUpdate(order.OrderID, "Hoàn thành")} class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 border border-gray-500 rounded m-0.5 ">HT</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <MsBoxModal handleClose={handleCloseModal} open={openAuthModal} Message={message} />
    </div>
  )
}
