import axios from "axios"

//export const API_BASE_URL= "https://localhost:44302/"
export const API_BASE_URL= "https://mapi.pt2000.vn/"
export const IMAGES_URL_NB = "https://mapi.pt2000.vn/uploads/NB/"
export const IMAGES_URL_NBNS = "https://mapi.pt2000.vn/uploads/NBNS/"
const jwt=localStorage.getItem("jwt")

export const api=axios.create({
    baseURL:API_BASE_URL,
    headers:{
        "Content-type": "application/json",
        "Authorization":`Bearer ${jwt}`
    }
})