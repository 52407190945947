import { api } from "../../config/apiConfig";

export const getAllColor = () => async()=>{
    try{
        const {data}=await api.get(`color/getAllColor`)
        return data;
    }catch{  }{
        return "";
    }
};
export const postColor = (colorData) => async()=>{
    try{
        const {data}=await api.post(`color/postColor`,colorData)
        return data;
    }catch{  }{
        return "";
    }
};
export const deleteColor = (colorData) => async()=>{
    try{
        const {data}=await api.delete(`color/deleteColor/${colorData}`)
        return data;
    }catch{  }{
        return "";
    }
};
// export const deleteProduct = (code) => async()=>{
//     try{
//         const {data}=await api.delete(`/api/Color/color/deleteColor/${code}`)
//         return data;
//     }catch{  }{
//         return "";
//     }
// };
export const DeleteProductColorSize = (code) => async()=>{
    try{
        const {data}=await api.delete(`productcolorsize/deleteproductcolorsizebycode/${code}`)
        return data;
    }catch{  }{
        return "";
    }
};
export const ActiveProductColorSize = (code) => async()=>{
    try{
        const {data}=await api.put(`productcolorsize/activeproductcolorsizebycode/${code}`)
        return data;
    }catch{  }{
        return "";
    }
};
export const UnActiveProductColorSize = (code) => async()=>{
    try{
        const {data}=await api.put(`productcolorsize/unactiveproductcolorsizebycode/${code}`)
        return data;
    }catch{  }{
        return "";
    }
};